




































































import { buildingLeaseApply, LandBlockType } from '@/common/api/modules/smartLocation'
import ElFormPlus,{ FormStructure } from '@/components/el-form-plus.vue';

import { defineComponent,onMounted,PropType, Ref, ref, watch } from '@vue/composition-api'
import { Message } from 'element-ui';
import houseTypeListDic, { HouseType } from '../houseType';
/**
 * 申请相关逻辑
* */ 
function  useApply(land:LandBlockType,houseTypeNum:Ref<ApartmentApplyForm['houseTypeNum']>){
  /** 安心公寓申请表单 */
  const apartemtApplyForm = ref<ApartmentApplyForm>({
    contactPerson: '',
    phone: '',
    peaceRentForm:[],
    peaceLegalPersonIdCard:[],
    peaceEnterpriseOperation:[],
    peaceTenants:[],
    peaceLaborContract:[]
  });
  /**
   * 人才公寓申请表单
   */
  const talentApartemtApplyForm =  ref<TalentApartmentApplyForm>({
    contactPerson: '',
    phone: '',
    talentLeaseApplication:[], //租赁申请表
    talentBusinessLicense:[],//营业执照复印件
    talentLegalPersonIdCard:[],//法人身份证复印件
    talentCheckInIdCard:[],//入住人的身份证复印件
    talentLaborContract:[],//劳动合同或社保缴纳证明

  })
  /** 安心公寓 */
  const apartmentApplyFormStructure:FormStructure<ApartmentApplyForm>[] =[
    {label:'联系人',key:'contactPerson',type:'text'},
    {label:'联系电话',key:'phone',type:'text'},
    {label:'企业配租审批表',key:'peaceRentForm',type:'file'},
    {label:'企业营业执照及法人身份证复印件',key:'peaceLegalPersonIdCard',type:'file'},
    {label:'企业经营情况说明',key:'peaceEnterpriseOperation',type:'file'},
    {label:'公寓租住人情况表',key:'peaceTenants',type:'file'},
    {label:'员工企业用工证明',key:'peaceLaborContract',type:'file'},
  ]
   /** 人才公寓 */
  const  talentApartmentApplyFormStructure:FormStructure<TalentApartmentApplyForm>[] =[
    {label:'联系人',key:'contactPerson',type:'text'},
    {label:'联系电话',key:'phone',type:'text'},
    {label:'租赁申请表',key:'talentLeaseApplication',type:'file'},
    {label:'营业执照复印件',key:'talentBusinessLicense',type:'file'},
    {label:'法人身份证复印件',key:'talentLegalPersonIdCard',type:'file'},
    {label:'入住人的身份证复印件',key:'talentCheckInIdCard',type:'file'},
    {label:'劳动合同或社保缴纳证明',key:'talentLaborContract',type:'file'},
  ]
  /** 显示表单弹窗控制 */
  let showApplyDialog = ref(false)
  /** 表单提交 */
  const handleSubmitApplyForm =  async(form:ApartmentApplyForm)=>{
     form.houseTypeNum = houseTypeNum.value;
     form.land = land;
   
    console.log(`表单提交`,form)
     let res = await buildingLeaseApply(form);
     Message.success('申请成功')
     handleCancelApplyDialog();
     console.log(`表单提交返回值`,res)
  }
  /** 显示申请表单 */  
  const handleShowApplyDialog  = ()=>{
    showApplyDialog.value = true;
  }
    /** 隐藏申请表单 */  
  const handleCancelApplyDialog  = ()=>{
    showApplyDialog.value = false;
  }
  
  
  return {
    
    applyForm:land === '2' ? apartemtApplyForm : talentApartemtApplyForm,showApplyDialog,apartmentApplyFormStructure: land ==='2' ? apartmentApplyFormStructure : talentApartmentApplyFormStructure,handleShowApplyDialog,handleSubmitApplyForm,handleCancelApplyDialog}
}
/** 房型操作相关逻辑 */
function useHouseType(land:LandBlockType){
    let selectedHouseType = ref<string[]>([]);
  

    /** 房型数量填写的表单数据 */
    let houseTypeNum =ref<ApartmentApplyForm['houseTypeNum']>([])
    watch(selectedHouseType,(nv)=>{
        houseTypeNum.value = nv.map(item=>({type:item.replace('户型',''),num:1})) 
    },{deep:true})
    /** 点击卡片选择房型 */  
    const handleHouseTypeChoose = (type:HouseType)=>{
        console.log(`选中房型`,type)
        /** 已选择的房型 */
        const index = selectedHouseType.value.findIndex(item=>item === type.name)
        if(index!== -1){
        selectedHouseType.value.splice(index,1);
        }else{
        selectedHouseType.value.push(type.name)
        }
        console.log(`选中房型`,{type,selectedHouseType})
    }
    /** 户型图预览模块 */
    let curCover = ref()
    let showPreviwCoverDialog = ref(false)
    let houseTypeList = ref<HouseType[]>([]);
    onMounted(()=>{
        houseTypeList.value = houseTypeListDic[land]
    })

    const handleShowCover = (item:any)=>{
        curCover.value = item.cover;
        showPreviwCoverDialog.value = true;
    }
    return {houseTypeList,selectedHouseType,houseTypeNum,curCover,showPreviwCoverDialog,handleShowCover,handleHouseTypeChoose}
}


export default defineComponent({
    components:{ElFormPlus},
    props:{
        land:{
            required:true,
            type:String as PropType<LandBlockType>
        },
    },
    setup(props) {
        /** 
         * 公共部分
         */
        const land = props.land;
        const houseType = useHouseType(land)
        /**
         * 房型选择
         */
        return {
            ...useApply(land,houseType.houseTypeNum),
            ...houseType
        }
    },
})

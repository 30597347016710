/**
 * 智能选址模块接口
 */
 //@ts-ignore
 import { $get, $post, $dataPost, $put } from "../../../http/axios"

type BaseResponse<T> ={
  /** @desc 状态码 */
  code: number
  /** @desc 消息 */
  message: string
  /** @desc 返回数据 */
  result: T
  /** @desc 逻辑状态 */
  success: boolean

}
export enum LandBlockType{
      /** @desc 发展大楼 */
      BUILDING="1",
      /** @desc 安心公寓 */
      APARTMENT="2",
      /** @desc 标准厂房 */
      FACTORY="3",
      /** @desc 人才公寓 */
      GENIUS_APARTMENT="4",

}

export type FileTemplateItem = {name:string,url:string}


export type LandBlockItem={
    /** @desc 地块名称 */
    name?:string,
    /** @desc 地块类型 */
    type?:LandBlockType,
    /** @desc 供地面积 */
    area?:number | string,
    /** @desc 用途 */
    use?:string,
    /** @desc 出让方式 */
    transformation?:string
    /** @desc 容积率 */
    volumeRate?:number| string
    /** @desc 绿化率 */
    greeningRate?:number | string,
    /** @desc 封面大图 */
    coverImage?:string,
    /** @desc 圆形放大图 */
    zoomImage?:string,
    /** @desc 右上方小图 */
    smallImage?:string,
    /** @desc 园区平面图 */
    planOverview?:string,
    /** @desc 申请材料模板 */
    fileTemplate?:Array<FileTemplateItem>,
    /** 选区 */
    selectArea:{
      width?:number,
      height?:number,
      top?:number,
      left?:number,
      bg?:string
    }
    
}
/** @desc 大楼租赁信息 */
export type BuildingLeaseInfo  = {
  /** @desc 大楼编号 */
  build:string,
  /** @desc 大楼名称 */
  buildName:string,
  /** @desc 所属地块类型 */
  land:LandBlockType,
  /** @desc 已租赁房间数量 */
  leasedNum:number,
  /** @desc 未租赁房间数量 */
  notLeasedNum:number,
  /** @desc 总数量 */
  totalNum:number,
  rate?:number,
  color?:string
}

/** 选房申请表单 */
export type SelectHouseApplyForm ={
  [key:string]:any,
  /** 联系人 */
  contactPerson: '',
  /** 联系电话 */
  phone: '',
  //营业执照复印件
  businessLicense:string[],
  //法人身份证复印件
  legalPersonIdCard:string[],
  //供经办人身份证复印件及委托书
  powerAttorneyOperatorIdCard:string[],
  //企业相关资质证明复印件
  certificatesEnterprise:string[],
  //招商引资企业另需再提供投资协议书
  investmentAgreement:string[],
  //温州瓯江口产业集聚区发展大厦租赁准入申请表
  applicationFormLeaseAccess:string[],
  //温州瓯江口产业集聚区迁入企业申请表
  relocatedEnterprises:string[],
  //入驻企业提供一份书面报告
  writtenReport:string[],
}
/** 选厂房申请表单 */
export type SelectFactoryApplyForm ={
  [key:string]:any,
  roomId?:number,
  /** 联系人 */
  contactPerson: '',
  /** 联系电话 */
  phone: '',
  /** 温州市瓯江口新区标准厂房申请表 */
  standardWorkshop:string[] | string,
  /** 营业执照复印件 */
  standardBusinessLicense:string[]| string,
  /** 法人身份证复印件 */
  standardLegalPersonIdCard:string[]| string,
  /** 委托书及被委托人身份证复印件 */
  standardPowerAttorneyIdCard:string[]| string,
  /** 书面申请报告 */
  standardApplicationReport:string[]| string,
}

/** @desc 房间信息 */
export type RoomInfo = {
  /** @desc 房间号 */
  roomNumber:string,
  /** @desc 房间id */
  id:number,
  /** @desc 楼层 */
  floor:string,
  /** @desc 承租公司 */
  leaseReserveBusiness:string,
  /** @desc 公摊面积 */
  sharedArea:number,
  /** @desc 建筑面积 */
  totalArea:number,
  /** @desc 使用面积 */
  userArea:number,
  /** @desc 租赁状态 */
  leaseStatus:string
}

/** @desc 房间列表 */
export type HouseListItem = {
    [x in number | 'floor' ]:RoomInfo 
}
/** @desc 房间选择申请表单 */
export type ApplyForm= {
  /** @desc 房间ID */
  roomId?:number,
  /** @desc 联系人 */
  contactPerson:string,
  /** @desc 联系电话 */
  phone:string
}

/**
 * 地块选择 假数据
 */
const landBlockList:LandBlockItem[]=[
    {
      name:"发展大楼",
      type:LandBlockType.BUILDING,
      area:48989,
      use:"商务金融用地",
      transformation:"挂牌出让",
      volumeRate:2.86,
      greeningRate:35,
       /** @desc 封面大图 */
      coverImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/8f85f134-094d-4aee-9e89-821a943934fa.png",
      /** @desc 圆形放大图 */
      zoomImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/d861d411-9f7d-487a-8691-eb1cc34d6b4d.png",
      /** @desc 右上方小图 */
      smallImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/5bd504da-5006-4010-b70b-d76d3835a298.png",
      /** @desc 园区平面图 */
      planOverview:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/015b315a-34cb-4e0f-91e9-5a7cd15de361.png",
      fileTemplate:[
        {name:'发展大厦租赁准入申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/发展大厦租赁准入申请表及材料.docx'},
        {name:'发展大厦租赁续租申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/发展大厦租赁续租申请表及材料.docx'}
      ],
      selectArea:{width:87,height:85,left:166,top:219,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land1.png'},

    },
    
    {
      name:"安心公寓",
      type:LandBlockType.APARTMENT,
      area:"-",
      use:"商务金融用地",
      transformation:"-",
      volumeRate:"-",
      greeningRate:"-",
      smallImage:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/thumb/2.png',

      fileTemplate:[
        {name:'安心公寓企业配租审批表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/瓯江口安心公寓企业配租审批表及申请材料.docx'},
        {name:'安心公寓企业续租审批表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/瓯江口安心公寓企业续租审批表及申请材料.docx'}
      ],
      selectArea:{width:71,height:62,left:252,top:77,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land4.png'}
    
    },
     {
      name:"标准厂房",
      type:LandBlockType.FACTORY,
      area:63673.02,
      use:"商务金融用地",
      transformation:"挂牌出让",
      volumeRate:1.99,
      greeningRate:22.9,
      coverImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/95c1193c-1e77-4097-9966-dcebaaf5a3fe.png",
      zoomImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210730/ae505410-892f-4bdc-8a73-90d7e8c4de91.png",
      smallImage:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210804/ac71d432-7190-4041-a717-be0707e15708.png",
      planOverview:"https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000256018570F/20210810/43ebca68-3d38-46ef-84c0-098a6447a591.png",
      fileTemplate:[
        {name:'标准厂房申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/温州市瓯江口新区标准厂房申请表.docx'},
        {name:'标准厂房续签申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/温州市瓯江口新区标准厂房续签申请表.docx'}
      ],
      selectArea: {width:51,height:69,left:240,top:82,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land3.png'}
    },
    {
      name:"人才公寓",
      type:LandBlockType.GENIUS_APARTMENT,
      area:"-",
      use:"-",
      transformation:"-",
      volumeRate:"-",
      greeningRate:"-",
      smallImage:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/thumb/4.png',
      fileTemplate:[
        {name:'人才公寓租赁申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/人才公寓租赁申请表及材料.docx'},
        {name:'人才公寓续租申请表模板',url:'https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/人才公寓续租申请表及材料.docx'}
      ],
      selectArea:{width:80,height:98,left:210,top:58,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land2.png'}

    },
]

/**
 * @desc 获取地块列表
 * @returns 
 */
export async  function getLandBlockList():Promise<LandBlockItem[]>{
    return landBlockList
}

/**
 * 获取地块基础信息
 * @param {LandBlockType} type  地块类型
 * @returns {Promise<LandBlockItem>}
 */
export async function getLandBlockBaseInfo(type:LandBlockType):Promise<LandBlockItem>{
  return landBlockList.find(item=>item.type === type)!
}


/** 
 * @desc 获取大楼租赁信息 
 * @param {LandBlockType} type 地块类型
 * @returns {Promise<BuildingLeaseInfo[]>}
 * */
export async function getBuildingLeaseInfo(type:LandBlockType):Promise<BuildingLeaseInfo[]>{
  let data  = await  $post(`/buildingleasemanagement/findBuildingLeaseNumListByLand?land=${type}`)
  return data.result
}
/**
 * 获取房间列表
 * @param {LandBlockType} land 地块类型
 * @param {string} build 大楼编号
 */
export async function getBuildingHouseList({land,build}:{land:LandBlockType,build:string}):Promise<HouseListItem[]> {
  let data = await  $post('/buildingleasemanagement/findBuildingLeaseListByBuildNO', {land,build})
  return data.result;
  
} 



/**
 * 房间申请表单
 * @param {ApplyForm} form 
 * @returns 
 */
export async function buildingLeaseApply(form:SelectHouseApplyForm):Promise<BaseResponse<any>>
export async function buildingLeaseApply(form:ApartmentApplyForm):Promise<BaseResponse<any>>
export async function buildingLeaseApply(form:SelectFactoryApplyForm):Promise<BaseResponse<any>>
export async function buildingLeaseApply(form:SelectHouseApplyForm):Promise<BaseResponse<any>>
export async function buildingLeaseApply(form:SelectFactoryApplyForm | ApartmentApplyForm  | TalentApartmentApplyForm | SelectHouseApplyForm):Promise<BaseResponse<any>> {
  let data = Object.assign({},form)
  for(let key in data){ // 如果存在数组或对象 进行转JSON字符串操作
    if(data[key] instanceof Object){
        data[key]  = JSON.stringify(data[key])
    }
}
  return $post('/buildingleaseapply/buildingLeaseApplyForEnterprise', data,{})
}

/**
 * 获取申请记录
 * @param params 
 * @returns 
 */
export async function getApplyRecordList(params:Object){
   return $post('/buildingleaseapply/findBuildingLeaseApplyList',params)
}

/** 获取审核记录 */
export async function getCheckRecordList(applyId:number){
  return $post(`/buildingleaseapply/findBuildingLeaseApplyCheckDetailList`,{applyId})

}
/**
 * 获取分配记录
 * @param applyId  申请记录ID
 * @returns 
 */
export async function getDistributeRecordList(applyId:number) {
  return $post(`/buildingleaseapply/findBuildingLeaseApplyDistributeList`,{applyId})
}
import { LandBlockType } from "@/common/api/modules/smartLocation";
export type HouseType = {
    id?:string |number,
    name:string,
    desc:string,
    cover?:string,
    covers?:string[],
    area?:number,
  
  }


  export const  houseTypeListDic:{
    [key in LandBlockType]:HouseType[]

 } = {
    [LandBlockType.BUILDING]:[],
    [LandBlockType.FACTORY]:[],
    [LandBlockType.GENIUS_APARTMENT]:[
      { id:"A", name:"A户型", desc:"",covers:["https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000142939152L/20211122/05e365ae-ce0c-44ce-beec-fe3b638e2466.png","http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/housetype/a.jpeg"]  },
      { id:"B", name:"B户型", desc:"",covers:["https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000142939152L/20211122/a4453b19-f485-4153-b0b8-3ded60200d71.png","http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/housetype/b.jpeg"] },
      { id:"C", name:"C户型", desc:"",covers:["https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000142939152L/20211122/41c77d0f-5fac-44d8-b3c0-2587bee003db.png","http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/housetype/c.jpeg"] },
      { id:"D", name:"D户型", desc:"",covers:["https://ojkcyzn.wenzhou.gov.cn:8093/attachment//static//91330000142939152L/20211122/b61b91d1-91f5-46a0-8093-505716811bc6.png","http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/housetype/d.jpeg" ]} ],

    [LandBlockType.APARTMENT]:[
        { id:"A", name:"A户型", desc:"（6人间）",area:43.55 },
        { id:"B", name:"B户型", desc:"（8人间）",area:54.12 },
        { id:"C", name:"C户型", desc:"",area:110.8 },
        { id:"D", name:"D户型", desc:"",area:111 },
        { id:"E", name:"E户型", desc:"",area:138.6 },
        { id:"F", name:"F户型", desc:"",area:134.5 },
        { id:"G", name:"G户型", desc:"（一房一厨一卫一客厅）",area:52.6 },
        { id:"H", name:"H户型", desc:"（两房一厨一卫一客厅）",area:83.1 },
    ]
  }
  export default houseTypeListDic
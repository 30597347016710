
































































































































































































import { computed, defineComponent, onMounted,ref, SetupContext, watch } from '@vue/composition-api'
import { ApplyForm, buildingLeaseApply, BuildingLeaseInfo,FileTemplateItem,getBuildingLeaseInfo, getLandBlockBaseInfo,LandBlockItem,LandBlockType, SelectFactoryApplyForm,} from "@/common/api/modules/smartLocation";
import ElFormPlus, { FormStructure } from "@/components/el-form-plus.vue"
import houseTypeListDic, { HouseType } from './houseType';
import { Message } from 'element-ui';
import HouseTypeSelector from './components/HouseTypeSelector.vue';


/**
 * 地块相关逻辑
 */
function  useLand(ctx:SetupContext){
  onMounted(()=>getLandBlockDetaile())
  /** 地块基础信息 */
  let baseInfo = ref<LandBlockItem>({selectArea:{}});
  /**  地块列表 */
  let list = ref<LandBlockItem[]>([]); 
  /** 当前地块 */
  const land = computed<LandBlockType>(()=>ctx.root.$route.params.land as LandBlockType);
  /** 下载申请模板 */
  const handleDownloadTemplate = async(item:FileTemplateItem)=>{
    window.open(item.url)
  }
  /**
  * 获取地块详情
  */
  const getLandBlockDetaile =  async() => {
    let res = await getLandBlockBaseInfo(land.value);
    console.log("获取基础信息", res);
    baseInfo.value = res;
  }
  return {baseInfo,list,land,handleDownloadTemplate,getLandBlockDetaile}
}
/**
 * 大楼相关逻辑
 */
function useBuilding(land:LandBlockType,ctx:SetupContext){
  onMounted(()=>getBuildingList())
  /** 大楼列表 */
  let buildingList = ref<BuildingLeaseInfo[]>([]);
   /** 获取大楼列表 */
  const getBuildingList = async()=>{
      let res = await getBuildingLeaseInfo(land);
      console.log("大楼列表", res);
      buildingList.value = res.map(item=>{
          const rate = item.leasedNum / item.totalNum // 已出租 / 总数量 = 出租率
          const color = rate > 0.8 ? 'red' : rate >0.3 ? 'yellow' : 'green'
          return {...item,color};
      });
   }
  /**
   * 跳转到选房
   * @param {string} build 大楼编号
   */
  const  handleGotoSelectHouse = (item: BuildingLeaseInfo)=>{
    let { land, build } = item;
    ctx.root.$store.commit("updateValue", { notLeasedNum: item.notLeasedNum });
    ctx.root.$router.push({
      name: land === LandBlockType.FACTORY ? "selectFactory" : "selectHouse",
      params: { land, build },
    });
  }
  return {buildingList,getBuildingList,handleGotoSelectHouse}
}



export default defineComponent({
  name:"selectBuilding",
  components:{ElFormPlus,HouseTypeSelector},
  setup(prop,ctx) {
    let  index: number = -1;
    const  handleBack = () => ctx.root.$router.go(-1);
    function setActiveItem(index:number){}
    /**
     * 地块操作相关逻辑
     */
    const {land,list,baseInfo,getLandBlockDetaile,handleDownloadTemplate} = useLand(ctx);


    /**
     * 表单申请相关逻辑
     */
    const handleFormChange = (e:any)=>{
      console.log(`触发外部值更新`,e)
    }
    
    return {
      index,
      handleFormChange,
      land,
      list,
      baseInfo,
      setActiveItem,
      handleDownloadTemplate,
      getLandBlockDetaile,
      handleBack,
      ...useBuilding(land.value,ctx)
    }
  },
})




































import { defineComponent,reactive, ref,PropType, computed, watch, onMounted } from '@vue/composition-api';
import Uploader from "@/components/uploader.vue"

/**表单结构 */
export type FormStructure<T = {[key:string]:any}> = {
  label:string,
  required?:boolean,
  key:keyof T,
  type:'text' | 'textarea' | 'select' | 'file',
  options?:any[],
  rule?:any[],
  chidren?:FormStructure[]
}

export default defineComponent({
    components:{Uploader},
    props:{
      /** 表单结构 */
      structure:{
        required:true,
        type:Array as PropType<FormStructure[]>
      },
      value:{
        type:Object,
        default:()=>({})
      }
    },
    setup(props,ctx) {
        let formData = ref<{[key:string]:any}>({})
        // let formData = computed<{[key:string]:any}>({
        //   get(){
        //     return data
        //   },
        //   set(val){
        //       console.log(`表单写入`,val.value)
        //       ctx.root.$emit("input",val.value)
        //   }
        // });
        const initFormData = ()=>{
            const defaultValueDic = {text:'',textarea:'',select:'',file:[]}
            const inputValue = props.value;
            console.log('传入值',inputValue)
            const structure = props.structure
            formData.value  = Object.fromEntries(structure.map(item=>[item.key as string,inputValue[item.key] ? inputValue[item.key] : defaultValueDic[item.type]]))
        }
        onMounted(()=>{
           initFormData();
        })

        watch(formData,nv=>{
          console.log(`表单更新`,nv)
          ctx.emit("input",nv)
          ctx.emit("change",nv)
        },{deep:true})
        const handleSubmit= ()=>{
          console.log(`表单提交`,formData.value)
          ctx.emit('submit',formData.value)
        }
        const handleCancel= ()=>{
            ctx.emit('cancel',formData.value)
        }
        return {formData,formStructure:props.structure,handleSubmit,handleCancel}
        
    },
})
